import axios from 'axios'
import { store } from '@/store'
import router from '@/router'
import { apiUrl } from '@/env'
import { commitSetLoading } from '../store/main/mutations'
import { Vue } from 'vue-property-decorator'
export function requestToken(config) {
    const timeout = config.timeout || 5000
    // 1.创建axios的实例
    const instance = axios.create({
      baseURL: apiUrl,
      timeout
    })

    // 2.1.请求拦截的作用
    instance.interceptors.request.use((configs) => {
        if (store.state.main.token) { // 判断是否存在token，如果存在的话，则每个http header都加上token
            configs.headers.Authorization = `Bearer ${store.state.main.token}`
        }
        // config.cancelToken = store.state.main.source.token
        return configs
    }, (err) => {
        commitSetLoading(store, false)
        return Promise.reject(err)
    })
    // 2.2.响应拦截
    instance.interceptors.response.use((res) => {
        return res.data
    }, (err) => {
      commitSetLoading(store, false)
      if (err.response) {
        switch (err.response.status) {
            case 404:
                // 图片等资源未找到时跳转
                // 返回 404
                router.replace({
                    path: '404',
                    query: { redirect: router.currentRoute.fullPath },
                })
                break
            case 403:
              if (err.response.data.detail === 'this person already exists in the system.') {
                Vue.prototype.$message.error(
                  window['vm'].$i18n.tc('emailorphoneRepeat')
                )
              }  else {
                // 其他情况一律出固定消息
                errMsg(err)
              }
              break
            case 402:
              // 后台自定义消息
              if (err.response.data.error === 'emailRepeat') {
                Vue.prototype.$message.error(
                  window['vm'].$i18n.tc('emailRepeat')
                )
              } else if (err.response.data.error === 'phoneRepeat') {
                Vue.prototype.$message.error(
                  window['vm'].$i18n.tc('phoneRepeat')
                )
              } else if (err.response.data.error === 'Repeat') {
                Vue.prototype.$message.error(
                  window['vm'].$i18n.tc('emailRepeat')
                )
              } else if (err.response.data.error === 'nameRepeat') {
                Vue.prototype.$message.error(
                  window['vm'].$i18n.tc('nameRepeat')
                )
              } else {
                if (err.response.data.detail.constructor === Array && !err.response.data.detail[0].msg) {
                  err.response.error = true
                  return err.response
                }
                // 其他情况一律出固定消息
                errMsg(err)
              }
              break
            case 422:
                // 后台定义消息
                // 不返回具体内容，一律出共通消息
                errMsg(err)
                break
            default:
                errMsg(err)

                break
        }
      }
      return Promise.reject(err) // 返回接口返回的错误信息
    })
    // 3.发送真正的网络请求
    return instance(config)
}

const errMsg = (err) => {
  try {
    // 后台定义消息
    // 不返回具体内容，一律出共通消息
    if (err.response.data.detail) {
      if (typeof err.response.data.detail === 'string') {
        Vue.prototype.$message.error(
          err.response.data.detail
        )
      } else if (err.response.data.detail.constructor === Array) {
        Vue.prototype.$message.error(
          err.response.data.detail[0].msg
        )
      }
    } else if (err.response.data.error && typeof err.response.data.error === 'string') {
      Vue.prototype.$message.error(
        err.response.data.error
      )
    } else {
      // 其他情况一律出固定消息
      Vue.prototype.$message.error(
        window['vm'].$i18n.tc('global_error')
      )
    }
  } catch {
    Vue.prototype.$message.error(
      window['vm'].$i18n.tc('global_error')
    )
  }

}

// 将参数转换成 formdata 接收格式
export function formDataTrans(data) {
    const formData = new FormData()
    for (const key in data) {
      // eslint-disable-next-line no-prototype-builtins
      if (data.hasOwnProperty(key)) {
        if (data[key]) {
          if (data[key].constructor === Array) {
            if (data[key][0]) {
              if (data[key][0].constructor === Object) {
                formData.append(key, JSON.stringify(data[key]))
              } else {
                data[key].forEach((item, index) => {
                  formData.append(key + `[${index}]`, item)
                })
              }
            } else {
              formData.append(key + '[]', '')
            }
          } else if (data[key].constructor === Object) {
            formData.append(key, JSON.stringify(data[key]))
          } else {
            formData.append(key, data[key])
          }
        } else {
          if (data[key] === 0) {
            formData.append(key, '0')
          } else {
            formData.append(key, '')
          }
        }
      }
    }
    return formData
  }
